import { Component, OnInit } from '@angular/core';
import {ProjectThumbnail} from "../../daos/project-thumbnail";

@Component({
  selector: 'app-theme-nine',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  projectThumbnails: ProjectThumbnail[] = [
      {
        title: "Reporting Launchpad",
        category: "B2B - Data Dashboard/Reporting",
        description: "Empowering educators at partner institutions with improved data and insights to better understand usage and performance at the student/cohort level.",
        imageUrl: "assets/img/reporting-suite-dashboard-highlight.jpg",
        pageUrl: ""
      },
      {
        title: "Instructor Grading Center",
        category: "B2B - Learning Management System",
        description: "Transforming the legacy internal grading tool to an intuitive and scalable solution with a refreshed look for external partners.",
        imageUrl: "assets/img/grading-experience-highlight.jpg",
        pageUrl: ""
      },
      {
        title: "Quiz Builder",
        category: "B2B - Learning Management System",
        description: "Building a self-serving test creation and management tool for external educators to easily create, manage, and schedule custom tests.",
        imageUrl: "assets/img/quiz-builder-highlight.jpg",
        pageUrl: ""
      },
      {
        title: "Coffee ☕️",
        category: "Design System",
        description: "Consolidating 4+ design systems into one powerful yet scalable design system to create a consistent product experience across 5 workstreams and 10+ product lines.",
        imageUrl: "assets/img/design-system-highlight.jpg",
        pageUrl: ""
      }
    // {
    //   title: "ZipPals",
    //   category: "Product Design - iOS Mobile App",
    //   description: "ZipPals is a social networking app for New Yorkers. I was a Product Designer working with research data and led the product interface design.",
    //   imageUrl: "assets/img/zippals-mockup-thumbnail.jpg",
    //   pageUrl: "zippal",
    // },
    // {
    //   title: "Supstat Inc.",
    //   category: "UX Design - Responsive Web & Web App",
    //   description: "As the sole Designer, I spearheaded the evolution of new products and improving the experience of various products, including websites and LMS web app.",
    //   imageUrl: "assets/img/nycdsa_thumbnail.jpg",
    //   pageUrl: "nycdsa",
    // },
    // {
    //   title: "FIPPOA Website Redesign",
    //   category: "UX Design - Responsive Web",
    //   description: "I was a UX Consultant for the client, Fire Island Pines Property Owners Association. I conducted user research and spearheaded the redesign to improve the experience.",
    //   imageUrl: "assets/img/fippoa-mockup-thumbnail.jpg",
    //   pageUrl: "fippoa",
    // }
  ];


  constructor() { }

  ngOnInit(): void {
  }

}
